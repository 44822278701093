export function debounce(cb, delay = 250) {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      cb(...args)
    }, delay)
  }
}
export function throttle(cd, time = 300) {
  var t = null;
  return function () {
    if (t) return;
    t = setTimeout(() => {
      cd.call(this);
      t = null;
    }, time);
  }
}