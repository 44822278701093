<template>
  <div class="select-school">
    <van-cell-group class="search-block">
      <img class="icon-search" src="../../assets/image/icon_search.png" alt="">
      <van-field class="search-input" v-model="name" placeholder="输入名称进行搜索">
        <template #button>
          <van-button @click="search" class="search-btn">搜索</van-button>
        </template>
      </van-field>
    </van-cell-group>
    <div class="tip">学校名称按首字母进行排序（a-z）</div>
    <div class="loading" v-if="loading">
      <van-loading size="50px" vertical type="spinner">加载中...</van-loading>
    </div>
    <van-empty  :image="emptyImg" v-else-if="searchedSchoolList.length == 0 && !loading" :description="emptyDesc" />
    <div v-else @click="selectSchool(item)" v-for="item in searchedSchoolList" :key="item.id">
      <school-card :school-list="item"></school-card>
    </div>
  </div>
</template>

<script>
import { school } from '../../http/repair/school'
import { debounce } from '../../util'
import SchoolCard from './components/SchoolCard.vue'
export default {
  components: { SchoolCard },
  data() {
    return {
      name: '',
      schoolList: [],
      searchedSchoolList: [],
      loading: false,
      emptyDesc: '',
      emptyImg:''
    }
  },
  methods: {
    search() {
      let newList = []
      this.schoolList.forEach(item => {
        if (item.name.includes(this.name.toUpperCase())) {
          newList.push(item)
        }
      })
      this.searchedSchoolList = newList
    },
    selectSchool(item) {
      this.$store.commit('setSchoolId', item.id)
      this.$store.commit('setSchool', item.name)
      this.$router.push('/index')
    },
    async getSchoolList() {
      this.loading = true
      await school().then((res) => {
        const list = res.data.sort((a, b) => a.name.localeCompare(b.name, 'zh-Hans-CN', { sensitivity: 'accent' }));
        this.schoolList = list
        this.searchedSchoolList = list
        this.emptyDesc='无记录'
        this.emptyImg='default'
      }).catch((err)=>{
        this.emptyDesc='网络异常'
        this.emptyImg='network'
      }).finally(()=>{
        this.loading=false
      })

    }
  },
  created() {
    this.getSchoolList()
  }
}
</script>

<style lang="less" scoped>
.select-school {
  padding: 16px 12px;
}

.search-block {
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.icon-search {
  width: 15px;
  height: 15px;
  margin-left: 9px;
  margin-right: 12px;
}

.search-input {
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  height: 32px;
  background: #FFFFFF;
  border-radius: 6px;


  .van-field__control {
    padding: 7px 0;
  }
}

.search-btn {
  height: 32px;
  background: #38ADFF;
  border-radius: 0px 6px 6px 0px;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 20px;
}

.tip {
  margin-top: 12px;
  font-size: 12px;
  color: #999999;
  line-height: 17px;
}

.loading {
  margin-top: 200px;
}
</style>