<template>
  <div class="school-card">
    <img src="../../../assets/image/icon_dw.png" alt="" class="icon">
    <div class="school-message">
      <div class="school-name">{{schoolList.name||'未知'}}</div>
      <div class="school-address">{{schoolList.address||'未知'}}</div>
      <div v-if="schoolList.unrepairCount>0" class="amount">{{schoolList.unrepairCount}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['schoolList']
}
</script>

<style lang="less" scoped>
.school-card {
  position: relative;
  margin-top: 16px;
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  padding: 22px 16px;
}

.school-message {
  width: 100%;
  margin-left: 12px;
}

.icon {
  width: 20px;
  height: 20px;
}

.school-name {
  font-size: 16px;
  color: #333333;
  line-height: 22px;
}

.school-address {
  margin-top: 4px;
  font-size: 12px;
  color: #666666;
  line-height: 17px;
}

.amount {
  padding: 0 6px;
  max-width: 25px;
  height: 16px;
  background: #FA6400;
  border-radius: 8px;
  position: absolute;
  color: #FFFFFF;
  line-height: 16px;
  font-size: 12px;
  text-align: center;
  top: 28px;
  right: 18px;
}
</style>